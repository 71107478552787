<template>
  <Drawer
    v-model="show"
    width="720"
    :mask-closable="true"
    :styles="styles"
    :before-close="beforeDrawerClose"
  >
    <div>
      <h1>非遗民俗</h1>
      <div class="insert_form">
        <Form ref="folk" :rules="folkValidate" :model="folk">
          <FormItem label="标题" prop="title">
            <Input v-model="folk.title" placeholder="请输入标题"></Input>
          </FormItem>

          <FormItem label="简介" prop="brief">
            <Input v-model="folk.brief" placeholder="请输入简介"></Input>
          </FormItem>

          <FormItem label="列表图片上传" prop="imgUrl">
            <Upload
              :before-upload="handleUpload"
              action="//jsonplaceholder.typicode.com/posts/"
              type="drag"
            >
              <img
                :src="folk.imgUrl"
                alt
                v-if="folk.imgUrl"
                class="uploadedImg"
              />
              <div style="padding: 20px 0" v-else>
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或者拖拽上传</p>
              </div>
            </Upload>
            <div v-if="file !== null">
              需要上传的文件: {{ file.name }}

              <Button type="primary" @click="upload" :loading="loadingStatus">{{
                loadingStatus ? "上传中" : "点击上传"
              }}</Button>
            </div>
            <div class="tips">建议尺寸：800*1100px</div>
          </FormItem>
          <FormItem label="内容" prop="content">
            <Editor
              api-key="5b8tzntm76wasqc64oqlaum33kmg0d0cx2cwcw8it703sip3"
              :init="init"
              v-model="folk.content"
            ></Editor>
          </FormItem>
          <FormItem label="类别" prop="ftype">
            <RadioGroup v-model="folk.ftype">
              <Radio label="非遗博览">
                <span>非遗博览</span>
              </Radio>
              <Radio label="民俗文化">
                <span>民俗文化</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="所在位置" prop="location">
            <Input v-model="folk.location" placeholder="请填写位置"></Input>
          </FormItem>

          <FormItem>
            <Button type="warning" class="groupBtn" @click="save('folk')"
              >保存</Button
            >

            <Button class="groupBtn" @click="resetTable('folk')"
              >重置表单</Button
            >
          </FormItem>
        </Form>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    Editor,
  },
  props: ["show", "singleData"],

  watch: {
    singleData: {
      deep: true,
      handler(nV) {
        this.folk = nV;
      },
    },
  },
  computed: {
    theSingleData() {
      return this.singleData;
    },
  },
  data() {
    const validateListImg = (rule, value, callback) => {
      if (this.folk.imgUrl === "") {
        callback(new Error("请上传列表图片"));
      } else {
        callback();
      }
    };

    const richTextValidate = (rule, value, callback) => {
      if (this.folk.content === "") {
        callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };

    return {
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      folk: {
        title: "",
        imgUrl: "",
        brief: "",
        ftype: "非遗博览",
        location: "",
        content: "",
        status: 0,
      },
      folkValidate: {
        title: [
          {
            required: true,
            trigger: "blur",
            message: "请输入标题",
          },
        ],

        imgUrl: [
          {
            required: true,
            validator: validateListImg,
            trigger: "blur",
          },
        ],

        brief: [
          {
            required: true,
            message: "请输入简介",
            trigger: "blur",
          },
        ],

        ftype: [
          {
            required: true,
            message: "请选择类别",
            trigger: "blur",
          },
        ],

        content: [
          {
            required: true,
            validator: richTextValidate,
            trigger: "blur",
          },
        ],

        location: [
          {
            required: true,
            message: "请输入所在位置",
            trigger: "blur",
          },
        ],
      },
      //富文本编辑器初始化
      init: {
        language: "zh_CN",
        height: 300,
        max_height: 600,
        content_css: false,
        branding: false,
        menubar: false,
        element_format: "html",
        font_formats:
          "Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif",
        indentation: "2em",
        plugins: "image,paste,autoresize,code",
        //自动高度
        autoresize_on_init: true,
        //粘贴时清除格式
        paste_auto_cleanup_on_paste: true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        paste_strip_class_attributes: true,
        paste_data_images: true,
        toolbar:
          "code | undo redo | styleselect | bold italic removeformat| alignleft aligncenter alignright alignjustify | image ",
        //图片设置
        images_upload_url: `${this.$globalProperty.base_api_url}/upload`,
        formats: {
          removeformat: [
            {
              selector:
                "b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins",
              remove: "all",
              split: true,
              block_expand: true,
              expand: false,
              deep: true,
            },
            {
              selector: "span",
              attributes: ["style", "class"],
              remove: "empty",
              split: true,
              expand: false,
              deep: true,
            },
            {
              selector: "*",
              attributes: ["style", "class"],
              split: false,
              expand: false,
              deep: true,
            },
          ],
        },
      },
      //图片上传组件
      file: null,
      infofile: null,
      loadingStatus: false,
      //按钮loading样式
      btnLoading: false,
    };
  },
  methods: {
    //图片上传
    //封面图手动上传方法
    handleUpload(file) {
      this.file = file;
      return false;
    },
    async upload() {
      let formData = new FormData();
      formData.set("file", this.file);
      formData.set("name", this.file.name);
      let result = await this.$axios.post("/upload", formData);
      console.log(result);
      if (result.data.code == 0) {
        this.folk.imgUrl =
          this.$globalProperty.base_img_url + result.data.fileName;
        this.file = null;
      }
    },

    //重置表单
    resetTable(name) {
      this.$refs[name].resetFields();
    },
    //提交表单
    save(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          console.log(this.folk);
          let result = await this.$axios.post("/folk/update", this.folk);
          console.log(result);
          if (result.data.errno == 0) {
            this.$Message.success("成功保存");
            this.$emit("listUpdate");
          }
        } else {
          this.$Message.error("请检查表单!");
        }
      });
    },
    beforeDrawerClose() {
      return new Promise(() => {
        this.$emit("onDrawerClose", false);
      });
    },
  },
};
</script>

<style></style>
