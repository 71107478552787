import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $axios from "./utils/request";

//引入view-ui
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

Vue.use(ViewUI);

Vue.config.productionTip = false;
Vue.prototype.$axios = $axios;
Vue.prototype.$globalProperty = {
  base_api_url: "http://mapi.linkingtrip.com/private/api",
  base_img_url: "http://static.linkingtrip.com/",
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
