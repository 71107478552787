<template>
  <footer class="footer">©2021途秀互动</footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
.footer {
  height: 50px;
  line-height: 50px;
  background-color: #2d3246;
  color: #fff;
  text-align: center;
}
</style>