<template>
  <dashboard>
    <festivalAdd></festivalAdd>
  </dashboard>
</template>

<script>
import dashboard from "../../layouts/dashboard";
import festivalAdd from "../../components/dashboard/festival_add";
export default {
  components: {
    dashboard,
    festivalAdd,
  },
};
</script>

<style lang="scss">
</style>
