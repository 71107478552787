<template>
  <dashboard>
    <folkAdd></folkAdd>
  </dashboard>
</template>

<script>
import dashboard from "../../layouts/dashboard";
import folkAdd from "../../components/dashboard/folk_add";
export default {
  components: {
    dashboard,
    folkAdd,
  },
};
</script>

<style lang="scss">
</style>
