<template>
  <header class="header">
    <img src="../../assets/images/logo.png" alt="" />
    <h2>| 歙县小程序管理平台</h2>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss">
.header {
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 150px;
  border-bottom: 1px solid #eee;

  img {
    margin-right: 20px;
  }
}
</style>