<template>
  <Drawer
    v-model="show"
    width="720"
    :mask-closable="true"
    :styles="styles"
    :before-close="beforeDrawerClose"
  >
    <div>
      <h1>风景道修改</h1>
      <div class="insert_form">
        <Form ref="scenicRoad" :rules="scenicRoadValidate" :model="scenicRoad">
          <FormItem label="风景道标题" prop="title">
            <Input v-model="scenicRoad.title" placeholder="请输入标题"></Input>
          </FormItem>

          <Row :gutter="30">
            <Col :span="12">
              <FormItem label="列表图片上传" prop="listpic">
                <Upload
                  :before-upload="handleUpload"
                  action="//jsonplaceholder.typicode.com/posts/"
                  type="drag"
                >
                  <img
                    :src="scenicRoad.listpic"
                    alt
                    v-if="scenicRoad.listpic"
                    class="uploadedImg"
                  />
                  <div style="padding: 20px 0" v-else>
                    <Icon
                      type="ios-cloud-upload"
                      size="52"
                      style="color: #3399ff"
                    ></Icon>
                    <p>点击或者拖拽上传</p>
                  </div>
                </Upload>
                <div v-if="file !== null">
                  需要上传的文件: {{ file.name }}

                  <Button
                    type="primary"
                    @click="upload"
                    :loading="loadingStatus"
                    >{{ loadingStatus ? "上传中" : "点击上传" }}</Button
                  >
                </div>
                <div class="tips">建议尺寸：800*1100px</div>
              </FormItem>
            </Col>
            <Col :span="12">
              <FormItem label="详情图片上传" prop="infopic">
                <Upload
                  :before-upload="handleInfoUpload"
                  action="//jsonplaceholder.typicode.com/posts/"
                  type="drag"
                >
                  <img
                    :src="scenicRoad.infopic"
                    alt
                    v-if="scenicRoad.infopic"
                    class="uploadedImg"
                  />
                  <div style="padding: 20px 0" v-else>
                    <Icon
                      type="ios-cloud-upload"
                      size="52"
                      style="color: #3399ff"
                    ></Icon>
                    <p>点击或者拖拽上传</p>
                  </div>
                </Upload>
                <div v-if="infofile !== null">
                  需要上传的文件: {{ infofile.name }}

                  <Button
                    type="primary"
                    @click="infoupload"
                    :loading="loadingStatus"
                    >{{ loadingStatus ? "上传中" : "点击上传" }}</Button
                  >
                </div>
                <div class="tips">建议尺寸：800*500px</div>
              </FormItem>
            </Col>
          </Row>

          <div>概览</div>
          <Row :gutter="20">
            <Col :span="8">
              <FormItem label="为何去" prop="overview.why">
                <Input
                  v-model="scenicRoad.overview.why"
                  placeholder="为何去"
                ></Input>
              </FormItem>
            </Col>

            <Col :span="8">
              <FormItem label="何时去" prop="overview.when">
                <Input
                  v-model="scenicRoad.overview.when"
                  placeholder="何时去"
                ></Input>
              </FormItem>
            </Col>

            <Col :span="8">
              <FormItem label="最适合" prop="overview.best">
                <Input
                  v-model="scenicRoad.overview.best"
                  placeholder="最适合"
                ></Input>
              </FormItem>
            </Col>
          </Row>

          <div
            v-for="(item, index) in scenicRoad.recommends"
            :key="index"
            style="margin-bottom: 15px"
          >
            <div class="recommendsTitle" style="margin-bottom: 10px">
              推荐行程
            </div>
            <div class="recommends" @click="sureDay(index)">
              <span>第{{ item.time }}天</span>
              <div class="recommendsInfo">
                <FormItem
                  label="目的地"
                  :prop="'recommends.' + index + '.destination'"
                  :rules="{
                    required: true,
                    trigger: 'blur',
                    message: '请填写目的地',
                  }"
                >
                  <Input
                    v-model="item.destination"
                    placeholder="请填写目的地"
                  ></Input>
                </FormItem>

                <FormItem
                  label="精彩点"
                  :prop="'recommends.' + index + '.tips'"
                  :rules="{
                    required: true,
                    trigger: 'blur',
                    message: '请填写精彩提示',
                  }"
                >
                  <Input v-model="item.tips" placeholder="请填写精彩点"></Input>
                </FormItem>
                <FormItem
                  label="行程"
                  :prop="'recommends.' + index + '.trip'"
                  :rules="{
                    required: true,
                    trigger: 'blur',
                    message: '请填写行程',
                  }"
                >
                  <Input v-model="item.trip" placeholder="请输入行程"></Input>
                </FormItem>
              </div>
              <div>
                <Button
                  type="primary"
                  @click="addTrip"
                  style="margin-right: 5px"
                  ><Icon type="md-add"
                /></Button>
                <Button
                  type="error"
                  @click.stop="delTrip(index)"
                  v-if="index >= 1"
                  ><Icon type="md-close"
                /></Button>
              </div>
            </div>
          </div>

          <FormItem label="主要景点" prop="mainscenic">
            <Input
              v-model="scenicRoad.mainscenic"
              placeholder="请填写主要景点"
            ></Input>
          </FormItem>

          <FormItem>
            <Button type="warning" class="groupBtn" @click="save('scenicRoad')"
              >保存</Button
            >
            <Button class="groupBtn" @click="resetTable('scenicRoad')"
              >重置表单</Button
            >
          </FormItem>
        </Form>
      </div>
    </div>
  </Drawer>
</template>

<script>
export default {
  props: ["show", "singleData"],

  watch: {
    singleData: {
      deep: true,
      handler(nV) {
        this.scenicRoad = nV;
      },
    },
  },
  computed: {
    theSingleData() {
      return this.singleData;
    },
  },
  data() {
    const validateListImg = (rule, value, callback) => {
      if (this.scenicRoad.listpic === "") {
        callback(new Error("请上传列表图片"));
      } else {
        callback();
      }
    };
    const validateInfoImg = (rule, value, callback) => {
      if (this.scenicRoad.infopic === "") {
        callback(new Error("请上传详情图片"));
      } else {
        callback();
      }
    };

    return {
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      scenicRoad: {
        title: "",
        listpic: "",
        infopic: "",
        mainscenic: "",
        recommends: [
          {
            time: "",
            destination: "",
            tips: "",
            trip: "",
          },
        ],
        overview: {
          why: "",
          when: "",
          best: "",
        },
        status: 0,
      },
      scenicRoadValidate: {
        title: [
          {
            required: true,
            trigger: "blur",
            message: "请输入标题",
          },
        ],
        "overview.why": [
          {
            required: true,
            trigger: "blur",
            message: "请输入为何去",
          },
        ],
        "overview.when": [
          {
            required: true,
            trigger: "blur",
            message: "请输入何时去",
          },
        ],
        "overview.best": [
          {
            required: true,
            trigger: "blur",
            message: "请输入最适合",
          },
        ],

        listpic: [
          {
            required: true,
            validator: validateListImg,
            trigger: "blur",
          },
        ],

        infopic: [
          {
            required: true,
            validator: validateInfoImg,
            trigger: "blur",
          },
        ],
        mainscenic: [
          {
            required: true,
            message: "请输入主要景点",
            trigger: "blur",
          },
        ],
      },
      //图片上传组件
      file: null,
      infofile: null,
      loadingStatus: false,
      //按钮loading样式
      btnLoading: false,
    };
  },
  methods: {
    sureDay(index) {
      this.scenicRoad.recommends[index].time = String(index + 1);
    },
    addTrip() {
      this.scenicRoad.recommends.push({
        time: "",
        destination: "",
        tips: "",
        trip: "",
      });
    },
    delTrip(index) {
      this.scenicRoad.recommends.splice(index, 1);
    },
    //图片上传
    //封面图手动上传方法
    handleUpload(file) {
      this.file = file;
      return false;
    },
    async upload() {
      let formData = new FormData();
      formData.set("file", this.file);
      formData.set("name", this.file.name);
      let result = await this.$axios.post("/upload", formData);
      console.log(result);
      if (result.data.code == 0) {
        this.scenicRoad.listpic =
          this.$globalProperty.base_img_url + result.data.fileName;
        this.file = null;
      }
    },

    handleInfoUpload(file) {
      this.infofile = file;
      return false;
    },
    async infoupload() {
      let formData = new FormData();
      formData.set("file", this.infofile);
      formData.set("name", this.infofile.name);
      let result = await this.$axios.post("/upload", formData);
      console.log(result);
      if (result.data.code == 0) {
        this.scenicRoad.infopic =
          this.$globalProperty.base_img_url + result.data.fileName;
        this.infofile = null;
      }
    },
    //重置表单
    resetTable(name) {
      this.$refs[name].resetFields();
    },
    //提交表单
    save(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          console.log(this.scenicRoad);
          let result = await this.$axios.post(
            "/scenicroad/update",
            this.scenicRoad
          );
          console.log(result);
          if (result.data.errno == 0) {
            this.$Message.success("成功保存");
          }
        } else {
          this.$Message.error("请检查表单!");
        }
      });
    },
    submitTable(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          this.scenicRoad.status = 1;
          let result = await this.$axios.post(
            "/scenicroad/insert",
            this.scenicRoad
          );
          console.log(result);
          if (result.data.errno == 0) {
            this.$Message.success("成功保存");
            this.resetTable(name);
          }
        } else {
          this.$Message.error("请检查表单!");
        }
      });
    },
    beforeDrawerClose() {
      return new Promise(() => {
        this.$emit("onDrawerClose", false);
      });
    },
  },
};
</script>

<style></style>
