<template>
  <Layout>
    <Header class="header">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" />
        <h3>小程序管理</h3>
      </div>

      <div class="profile">
        <span><Icon type="md-person" />管理员</span>
        <Button class=""><Icon type="md-log-out" />登出</Button>
      </div>
    </Header>
    <Layout class="mid">
      <Row>
        <Col :span="4">
          <Menu theme="light" class="midMenu">
            <MenuGroup title="旅游风景道管理">
              <MenuItem name="1" to="/dashboard/scenicroad_add">
                <Icon type="md-add" />
                风景道添加
              </MenuItem>
              <MenuItem name="2" to="/dashboard/scenicroad_list">
                <Icon type="md-list" />
                风景道列表
              </MenuItem>
            </MenuGroup>
            <MenuGroup title="非遗民俗管理">
              <MenuItem name="3" to="/dashboard/folk_add">
                <Icon type="md-add" />
                非遗民俗添加
              </MenuItem>
              <MenuItem name="4" to="/dashboard/folk_list">
                <Icon type="md-list" />
                非遗民俗列表
              </MenuItem>
            </MenuGroup>
            <MenuGroup title="节庆活动管理">
              <MenuItem name="5" to="/dashboard/festival_add">
                <Icon type="md-add" />
                节庆活动添加
              </MenuItem>
              <MenuItem name="6" to="/dashboard/festival_list">
                <Icon type="md-list" />
                节庆活动列表
              </MenuItem>
            </MenuGroup>
            <MenuGroup title="徽州美食管理">
              <MenuItem name="7" to="/dashboard/food_add">
                <Icon type="md-add" />
                徽州美食增加
              </MenuItem>
              <MenuItem name="8" to="/dashboard/food_list">
                <Icon type="md-list" />
                徽州美食列表
              </MenuItem>
            </MenuGroup>
          </Menu>
        </Col>
        <Col :span="20">
          <div class="mainContent"><slot> </slot></div>
        </Col>
      </Row>
    </Layout>
    <Footer class="copyRight">技术支持:途秀互动</Footer>
  </Layout>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.header {
  background: #fff;
  height: 80px;
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .logo {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 8px #eee;
    border-radius: 10px;
    padding: 0 20px;
    img {
      max-width: 80px;
      margin-right: 10px;
    }
  }
  .profile {
    span {
      margin-right: 20px;
    }
  }
}
.midMenu {
  margin: 30px 0 0 20px;
}

.mainContent {
  background: #fff;
  margin: 30px 30px 20px 0;
  padding: 50px;
}

.copyRight {
  background: #34333f;
  color: #fff;
  text-align: center;
}
</style>
