import axios from 'axios'

const $axios = axios.create({
  baseURL: 'http://mapi.linkingtrip.com/private/api/',
  headers: { 'Content-Type': "application/json" }
})


$axios.interceptors.response.use(res => {
  return res
})

export default $axios
