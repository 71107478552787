<template>
  <dashboard>
    <foodAdd></foodAdd>
  </dashboard>
</template>

<script>
import dashboard from "../../layouts/dashboard";
import foodAdd from "../../components/dashboard/food_add";
export default {
  components: {
    dashboard,
    foodAdd,
  },
};
</script>

<style lang="scss">
</style>
