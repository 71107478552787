import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login.vue";
import scenicRoad from "../views/dashboard/scenicRoad.vue";
import scenicRoadList from "../views/dashboard/scenicRoadList.vue"
import folkAdd from "../views/dashboard/folk_add.vue"
import folkList from "../views/dashboard/folk_list.vue"
import festivalAdd from "../views/dashboard/festival_add.vue"
import festivalList from "../views/dashboard/festival_list.vue"
import foodAdd from "../views/dashboard/food_add.vue"
import foodList from "../views/dashboard/food_list.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/dashboard/scenicroad_add",
    component: scenicRoad
  },
  {
    path: "/dashboard/scenicroad_list",
    component: scenicRoadList
  },
  {
    path: "/dashboard/folk_add",
    component: folkAdd
  },
  {
    path: "/dashboard/folk_list",
    component: folkList
  },
  {
    path: "/dashboard/festival_add",
    component: festivalAdd
  },
  {
    path: "/dashboard/festival_list",
    component: festivalList
  },
  {
    path: "/dashboard/food_add",
    component: foodAdd
  },
  {
    path: "/dashboard/food_list",
    component: foodList
  },
];

const router = new VueRouter({
  routes
});

export default router;
