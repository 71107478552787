<template>
  <common>
    <Row :gutter="20" type="flex" justify="center" align="middle" class="login">
      <Col :span="12">
        <img src="../assets/images/loginDec.png" alt="" />
      </Col>
      <Col :span="12">
        <div class="loginForm">
          <h1>管理平台登录</h1>
          <Form ref="formInline" :model="formInline" :rules="ruleInline">
            <FormItem prop="user">
              <Input
                type="text"
                v-model="formInline.user"
                placeholder="用户名"
                size="large"
              >
                <Icon type="ios-person-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="formInline.password"
                placeholder="密码"
                size="large"
              >
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem>
              <Vcode :show="isShow" @success="success" @close="close"></Vcode>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                size="large"
                long
                @click="handleSubmit('formInline')"
                >登录</Button
              >
            </FormItem>
          </Form>
        </div>
      </Col>
    </Row>
  </common>
</template>

<script>
import common from "../layouts/common";
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    common,
    Vcode,
  },
  data() {
    return {
      isShow: false,
      already: false,
      formInline: {
        user: "",
        password: "",
      },
      ruleInline: {
        user: [
          {
            required: true,
            message: "Please fill in the user name",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please fill in the password.",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: "The password length cannot be less than 6 bits",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async handleSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        this.isShow = true;
        if (valid &&
          this.formInline.user == "tourshow" &&
          this.formInline.password == "tourshow123456"
) {
          this.$router.push("/dashboard/scenicroad_add");
          this.$Message.success("成功登录");
        } else {
          this.$Message.error("请检查账号密码");
        }
      });
    },
    // 用户通过了验证
    success() {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      this.already = true;
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 45vw;
  background: linear-gradient(to bottom, rgb(21, 171, 241), #2f65e4);
  img {
    width: 60%;
    margin-left: 150px;
  }
  .loginForm {
    border-radius: 20px;
    padding: 30px 40px;
    background: #fff;
    max-width: 400px;
    margin: 0 auto;
    h1 {
      margin-bottom: 25px;
      text-align: left;
    }
  }
}
</style>
