<template>
  <dashboard>
    <div class="folkList">
      <h1>非遗民俗列表</h1>
      <Table
        border
        :columns="columns"
        :data="tableData"
        class="table"
        size="large"
      >
        <template slot-scope="{ row }" slot="name">
          <strong>{{ row.name }}</strong>
        </template>
        <template slot-scope="{ row }" slot="action">
          <Poptip
            confirm
            title="你确定要发布当前文章吗？"
            @on-ok="publish(row.id)"
            @on-cancel="deleteCancel"
            :transfer="true"
            v-if="row.status == 0"
          >
            <Button type="success" size="small" style="margin-right: 5px"
              >发布</Button
            >
          </Poptip>
          <Button
            type="primary"
            size="small"
            style="margin-right: 5px"
            @click="edit(row.id)"
            >修改</Button
          >
          <Poptip
            confirm
            title="你确定要删除当前文章吗"
            @on-ok="deleteOk(row.id)"
            @on-cancel="deleteCancel"
            :transfer="true"
          >
            <Button type="error" size="small">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>

    <folkDraw
      :show="isShow"
      :singleData="singleData"
      @onDrawerClose="onDrawerClose"
      @listUpdate="listUpdate"
    ></folkDraw>
  </dashboard>
</template>

<script>
import dashboard from "../../layouts/dashboard";
import folkDraw from "../../components/dashboard/folk_draw";
export default {
  components: {
    dashboard,
    folkDraw,
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          type: "index",
          width: 80,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          align: "center",
        },
        {
          title: "类别",
          key: "ftype",
          align: "center",
        },
        {
          title: "列表图",
          key: "imgUrl",
          align: "center",
          render: (h, params) => {
            return h("img", {
              attrs: {
                src: params.row.imgUrl,
              },
              style: {
                width: "100%",
                padding: "10px 0",
              },
            });
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          align: "center",
          render: (h, params) => {
            return h("Time", {
              props: {
                time: params.row.createdAt,
              },
              attrs: {
                type: "datetime",
              },
            });
          },
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          render: (h, params) => {
            //  <Tag color="warning">warning</Tag>
            if (params.row.status == 0) {
              return h(
                "Tag",
                {
                  props: {
                    color: "warning",
                  },
                },
                "草稿"
              );
            } else {
              return h(
                "Tag",
                {
                  props: {
                    color: "success",
                  },
                },
                "已发布"
              );
            }
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
        },
      ],
      tableData: [],

      //是否开启抽屉
      isShow: false,
      singleData: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let result = await this.$axios.get("/folk/list");
      if (result.data.errno == 0) {
        this.tableData = result.data.data;
      }
    },
    async deleteOk(id) {
      let result = await this.$axios.get(`/folk/delete/${id}`);
      if (result.data.errno == 0) {
        this.$Message.success("成功删除");
        await this.getData();
      }
    },
    async publish(id) {
      let result = await this.$axios.post(`/folk/update/${id}/1`);
      if (result.data.errno == 0) {
        this.$Message.success("发布");
        await this.getData();
      }
    },
    deleteCancel() {},

    async edit(id) {
      let result = await this.$axios.get(`/folk/single/${id}`);
      console.log(result);
      if (result.data.errno == 0) {
        this.isShow = true;
        this.singleData = result.data.data[0];
      }
    },

    //抽屉关闭
    onDrawerClose(val) {
      this.isShow = val;
    },
    listUpdate() {
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.folkList {
  .table {
    margin: 30px 0;
  }
}
</style>
