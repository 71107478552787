<template>
  <dashboard>
    <scenicRoad></scenicRoad>
  </dashboard>
</template>

<script>
import dashboard from "../../layouts/dashboard";
import scenicRoad from "../../components/dashboard/scenicRoad_add";
export default {
  components: {
    dashboard,
    scenicRoad,
  },
};
</script>

<style lang="scss">
.insert_form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.tips {
  text-align: center;
  background: #f8f8f8;
  opacity: 0.5;
  border-radius: 8px;
  font-size: 12px;
}

.recommends {
  padding: 15px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: #f8f8f8;
  border-radius: 8px;
  .recommendsInfo {
    flex-wrap: wrap;

    display: flex;
    flex-direction: row;
  }
}
.groupBtn {
  margin-right: 10px;
}
.uploadedImg {
  width: 100%;
}
</style>
