<template>
  <div class="common">
    <Header></Header>
    <slot></slot>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/public/header";
import Footer from "../components/public/footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss"></style>
